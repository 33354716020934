.copyright {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 20.16px;
  text-align: left;
  text-transform: uppercase !important;
}

.privacy {
  font-size: 13px;
  font-weight: 400;
  line-height: 16.38px;
}

.company-name {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 20.16px;
  text-transform: uppercase !important;
}
.underline {
  text-decoration-line: underline;
}

.footer,
.footer a,
.footer li,
.footer span {
  font-size: 15px;
  font-weight: 400;
  line-height: 18.9px;
}

.footer li {
  height: 44px;
}

@media (max-width: 1919px) {
  .footer li {
    height: 40px;
  }
}
@media (max-width: 1365px) {
  .footer,
  .footer a,
  .footer li,
  .footer span {
    font-size: 13px;
    font-weight: 400;
    line-height: 16.38px;
  }
}
@media (max-width: 1023px) {
}
@media (max-width: 768px) {
  .footer,
  .footer a,
  .footer li,
  .footer span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.12px;
  }
}
