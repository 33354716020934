.copyright {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 20.16px;
  text-align: left;
  text-transform: uppercase !important;
}

.privacy {
  font-size: 13px;
  font-weight: 400;
  line-height: 16.38px;
}

.company-name {
  font-size: 16px;
  font-weight: 700 !important;
  line-height: 20.16px;
  text-transform: uppercase !important;
}
.underline {
  text-decoration-line: underline;
}

.footer,
.footer a,
.footer li,
.footer span {
  font-size: 15px;
  font-weight: 400;
  line-height: 18.9px;
}

.footer li {
  height: 44px;
}

@media (max-width: 1919px) {
  .footer li {
    height: 40px;
  }
}
@media (max-width: 1365px) {
  .footer,
  .footer a,
  .footer li,
  .footer span {
    font-size: 13px;
    font-weight: 400;
    line-height: 16.38px;
  }
}
@media (max-width: 1023px) {
}
@media (max-width: 768px) {
  .footer,
  .footer a,
  .footer li,
  .footer span {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.12px;
  }
}

@font-face {
font-family: '__WixMadeforDisplay_c8bd87';
src: url(/_next/static/media/37699f1aa147bf0f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__WixMadeforDisplay_c8bd87';
src: url(/_next/static/media/c11169bdd187a063-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__WixMadeforDisplay_c8bd87';
src: url(/_next/static/media/0aeea4001fa93ec0-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__WixMadeforDisplay_c8bd87';
src: url(/_next/static/media/90248ce4324fb97a-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__WixMadeforDisplay_c8bd87';
src: url(/_next/static/media/1cc9459487c984f2-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}@font-face {font-family: '__WixMadeforDisplay_Fallback_c8bd87';src: local("Arial");ascent-override: 96.09%;descent-override: 24.02%;line-gap-override: 0.00%;size-adjust: 104.90%
}.__className_c8bd87 {font-family: '__WixMadeforDisplay_c8bd87', '__WixMadeforDisplay_Fallback_c8bd87'
}.__variable_c8bd87 {--font-wixmadefordisplay: '__WixMadeforDisplay_c8bd87', '__WixMadeforDisplay_Fallback_c8bd87'
}

@font-face {
font-family: '__Unbounded_8e6653';
src: url(/_next/static/media/1c9228d720f83c92-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 200;
font-style: normal;
}

@font-face {
font-family: '__Unbounded_8e6653';
src: url(/_next/static/media/7d861cc3e64c0118-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__Unbounded_8e6653';
src: url(/_next/static/media/5d8bb340ad81d78f-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__Unbounded_8e6653';
src: url(/_next/static/media/8b0406ece45ee2b6-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: '__Unbounded_8e6653';
src: url(/_next/static/media/5bdce92591bd8333-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 600;
font-style: normal;
}

@font-face {
font-family: '__Unbounded_8e6653';
src: url(/_next/static/media/7b853723a95c031d-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 700;
font-style: normal;
}

@font-face {
font-family: '__Unbounded_8e6653';
src: url(/_next/static/media/7fec9a4e12d824c7-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 800;
font-style: normal;
}

@font-face {
font-family: '__Unbounded_8e6653';
src: url(/_next/static/media/0f8a9620292dec37-s.p.ttf) format('truetype');
font-display: swap;
font-weight: 900;
font-style: normal;
}@font-face {font-family: '__Unbounded_Fallback_8e6653';src: local("Arial");ascent-override: 73.13%;descent-override: 18.01%;line-gap-override: 0.00%;size-adjust: 136.07%
}.__className_8e6653 {font-family: '__Unbounded_8e6653', '__Unbounded_Fallback_8e6653'
}.__variable_8e6653 {--font-unbounded: '__Unbounded_8e6653', '__Unbounded_Fallback_8e6653'
}

